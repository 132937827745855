import React from 'react'

import MediaTileBase from './MediaTileBase'

const MediaTile = (props) => {
  const poster = getPosterUrl(props)
  const type = getType(props)
  const persons = getPeople(props)
  const time = getTime(props)
  return (
    <MediaTileBase
      {...props}
      type={type}
      poster={poster}
      persons={persons}
      time={time}
    />
  )
}

const getType = ({ type, __typename }) => (type ? type : __typename)

const getPosterUrl = ({ poster, heroImage, videoAsset }) => {
  let posterUrl = poster && poster.url ? poster.url : poster
  if (!posterUrl) {
    posterUrl = heroImage ? heroImage?.image.url : videoAsset?.thumbnail.url
  }
  return posterUrl
}

const getTime = ({ duration, videoAsset }) => duration || videoAsset?.duration

const getPeople = ({ persons, personsCollection }) =>
  persons || personsCollection?.items

export default MediaTile
