import React from 'react'
import ReactContentfulImage from 'react-contentful-image'
import { useResizeObserver } from '@klickmarketing/react-components'
import styled, { css } from 'styled-components'

const roundToDecimal = (number, decimals) =>
  Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) /
  Math.pow(10, decimals)

const ContentfulImage = ({ src, alt, focalPoint, width, height, ...rest }) => {
  const hasFocalPoint = !!focalPoint

  const container = React.useRef()
  const imgContainer = React.useRef()
  const [cResizeEntry] = useResizeObserver(container?.current)

  const [loaded, setLoaded] = React.useState(0)

  React.useEffect(() => {
    if (
      !imgContainer.current ||
      !container.current ||
      !hasFocalPoint ||
      !loaded ||
      !cResizeEntry
    )
      return

    const imgTag = imgContainer.current.firstChild.lastChild
    const { naturalWidth, naturalHeight } = imgTag
    const { width: clientWidth, height: clientHeight } =
      cResizeEntry?.contentRect

    // figure out how much overflow there is along each axis
    let xOverflow = Math.round(naturalWidth - clientWidth)
    let yOverflow = Math.round(naturalHeight - clientHeight)

    // calculate the normalized (0-1) position of the focal point
    const xFPPercent = focalPoint.x / width
    const yFPPercent = focalPoint.y / height

    // calculate the focal points position relative to the current image
    const focalX = Math.floor(naturalWidth * xFPPercent)
    const focalY = Math.floor(naturalHeight * yFPPercent)

    let scaleFactor = 1

    // calculate size ratio for each axis
    const xScale = clientWidth / naturalWidth
    const yScale = clientHeight / naturalHeight
    // choose the largest ratio because the scalar is is applied to both height and width
    scaleFactor = roundToDecimal(Math.max(xScale, yScale), 5)
    // calculate the new overflow of the scaled image
    const newXOverflow = naturalWidth * scaleFactor - clientWidth
    // make sure the overflow is relative to the container
    xOverflow = xOverflow < 0 ? 0 : newXOverflow / scaleFactor
    const newYOverflow = naturalHeight * scaleFactor - clientHeight
    yOverflow = yOverflow < 0 ? 0 : newYOverflow / scaleFactor

    const cCenterX = clientWidth / 2
    const distanceX = (focalX * scaleFactor - cCenterX) / scaleFactor
    const offsetX = Math.min(
      (distanceX > xOverflow ? xOverflow : distanceX) * -1,
      0
    )

    const cCenterY = clientHeight / 2
    const distanceY = (focalY * scaleFactor - cCenterY) / scaleFactor
    const offsetY = Math.min(
      (distanceY > yOverflow ? yOverflow : distanceY) * -1,
      0
    )

    imgContainer.current.style.transform = `scale(${scaleFactor}) translate(${offsetX}px, ${offsetY}px)`
  }, [
    imgContainer,
    focalPoint,
    width,
    height,
    cResizeEntry,
    loaded,
    hasFocalPoint,
  ])

  let url = src
  const hasHttps = !!~src.indexOf('https:')
  if (hasHttps) url = src.split('https:')[1]
  return (
    <Container ref={container}>
      <ImageContainer ref={imgContainer} $hasFocal={hasFocalPoint}>
        <StyledImg
          src={url}
          alt={alt ? alt : ''}
          {...(!alt && { role: 'presentation' })}
          {...rest}
          $hasFocal={hasFocalPoint}
          onLoad={() => {
            setLoaded((loaded) => loaded + 1)
          }}
        />
      </ImageContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const ImageContainer = styled.div`
  height: ${({ $hasFocal }) => ($hasFocal ? `auto` : '100%')};
  width: ${({ $hasFocal }) => ($hasFocal ? 'auto' : '100%')};
  position: ${({ $hasFocal }) => ($hasFocal ? 'absolute' : 'relative')};
  transform-origin: top left;

  > picture {
    display: block;
    height: 100%;
  }
`

const StyledImg = styled(ReactContentfulImage)`
  ${({ $hasFocal }) =>
    $hasFocal
      ? css`
          width: unset !important;
          height: unset !important;
        `
      : css`
          width: 100%;
        `}
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
`

export default ContentfulImage
