import React from 'react'
import { connectAutoComplete } from 'react-instantsearch-dom'
import {
  Button,
  ThemeProvider,
  timestampToTime,
} from '@klickmarketing/react-components'
import {
  createStyles,
  makeStyles,
  TextField,
  useTheme,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { navigate } from 'gatsby'
import {
  Link as I18nLink,
  Trans,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { generatePostUri } from '../../common/utils'
import MediaTile from '../MediaTile/MediaTile'

import ListBox from './ListBox'

const useStyles = makeStyles((theme) =>
  createStyles({
    listbox: {
      padding: 0,
    },
    option: {
      // Hover
      '&[data-focus="true"]': {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
      },
    },
  })
)

const ConntectedAutocomplete = connectAutoComplete((props) => {
  const {
    hits,
    currentRefinement,
    refine,
    inputRef,
    indexContextValue,
    createURL,
    onSelect,
    onFocus,
    onBlur,
    wrapper,
    listBoxProps,
    dataRef,
    ...restProps
  } = props
  const theme = useTheme()
  const classes = useStyles()
  const options = hits.slice(0, 4)
  const { t } = useTranslation()

  React.useEffect(() => {
    if (dataRef.current) dataRef.current.refine = refine
  }, [refine, dataRef])

  return (
    <Autocomplete
      {...restProps}
      open={true}
      id="idx-quicksearch"
      classes={{
        option: classes.option,
        listbox: classes.listbox,
      }}
      disableListWrap
      disablePortal
      freeSolo
      options={currentRefinement !== '' ? options : []}
      clearOnBlur={false}
      ListboxComponent={ListBox}
      ListboxProps={listBoxProps}
      onChange={(_, option) => {
        refine('')
        if (!option) return
        onSelect(option)
        !option.type || option.type === 'showAllButton'
          ? navigate(`/discover?search=${currentRefinement}`)
          : navigate(generatePostUri(option))
      }}
      getOptionLabel={({ title }) => {
        return typeof title === 'string' || title instanceof String ? title : ''
      }}
      filterOptions={(options, { inputValue }) =>
        options.length < 4
          ? options
          : [
              ...options,
              {
                type: 'showAllButton',
              },
            ]
      }
      renderOption={({ title, type, objectID: id, duration, ...rest }) => {
        if (type === 'showAllButton')
          return (
            <StyledItem key={`showAllButton`}>
              <ShowAllButton
                size="large"
                color="secondary"
                component={I18nLink}
                to={`/discover?search=${currentRefinement}`}
              >
                <Trans>Show All</Trans>
              </ShowAllButton>
            </StyledItem>
          )
        return (
          <StyledItem key={id}>
            <MediaTile
              title={title}
              type={type}
              time={duration && timestampToTime(duration)}
              {...rest}
              variant="dense"
            />
          </StyledItem>
        )
      }}
      renderInput={(params) => {
        const content = (
          <ThemeProvider
            themeType={
              theme.themeType === 'onBlack' || theme.themeType === 'onPrimary'
                ? 'onWhite'
                : 'onBlack'
            }
          >
            <HiddenLabel htmlFor="idx-quicksearch">
              <Trans>Search</Trans>
            </HiddenLabel>
            <SearchTextField
              {...params}
              inputRef={inputRef}
              fullWidth
              variant="standard"
              name={t('search')}
              type="text"
              value={currentRefinement}
              placeholder={t('Search...')}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: undefined,
                autoComplete: 'new-password',
              }}
              inputProps={{
                ...params.inputProps,
                value: currentRefinement,
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                disabled: true,
              }}
              onChange={(event, ...args) => {
                refine(event.target.value)
                params.inputProps.onChange(event, ...args)
              }}
              onFocus={onFocus}
              onBlur={() => {
                // if (onBlur) onBlur()
              }}
            />
          </ThemeProvider>
        )
        if (wrapper) return wrapper(content)
        return content
      }}
    />
  )
})

const StyledItem = styled.div`
  width: 100%;
  position: relative;
  display: block;
`

const ShowAllButton = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  color: white;
`

const SearchTextField = styled(TextField)`
  margin-top: 4px;
`

const HiddenLabel = styled.label`
  display: none;
`

export default ConntectedAutocomplete
