import React from 'react'
import { setup as reactContentfulImageSetup } from 'react-contentful-image'
import { useAuth0 } from '@auth0/auth0-react'
import {
  AccessibilityDialog,
  BackToTop,
  Button,
  CookieBanner,
  EyebrowBar,
  Footer,
  LanguagePicker,
  MainNavBar,
  match,
  P,
  Seo,
  setCookie,
  setStorageItem,
  SkipToContentBar,
  tracking,
} from '@klickmarketing/react-components'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import {
  Link as I18nLink,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { IDX_FLOODLIGHT_TAGS } from '../../../config'
import { getLocalizedRelativePath } from '../../../languages'
import socialThumbPath from '../../assets/images/social-thumb.jpg'
import { useQuickSearchState } from '../../contexts/QuickSearchStateProvider'
import { useSiteMetadata } from '../../hooks/useSiteMetadata'
// import JoinUs from '../JoinUs/JoinUs'
import CtaBanner from '../CtaBanner/CtaBanner'
import QuickSearch from '../QuickSearch/QuickSearch'

import '@klickmarketing/react-components/dist/es/_assets/fonts/fonts.css'

const getMediaQuery = (matchQuery) => matchQuery.split('@media ')[1]
const media = {
  xs: getMediaQuery(match.isXS),
  sm: getMediaQuery(match.isSM),
  md: getMediaQuery(match.isMD),
  lg: getMediaQuery(match.isLG),
  xl: getMediaQuery(match.isXL),
  dpr2: '(min-resolution: 144dpi)', // 1.5x devices and up get 2x images
  dpr3: null, // 2.5x devices and up get 3x images
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)',
}

// Object keys are media query names from above. 'default' means no media query (default match).
// E.g. for mobile first 'default' would be the mobile media query
const variants = {
  default: {
    quality: 80,
    density: 1,
  },
  dpr2: {
    quality: 80,
    density: 2,
  },
  dpr3: null,
}

reactContentfulImageSetup(media, variants, { appendHTTPS: false })

const AuthButton = (props) => {
  return (
    <StyledButton
      size="large"
      variant="contained"
      color="primary"
      disableElevation
      {...props}
    >
      {props.label}
    </StyledButton>
  )
}

const LogoutButton = (props) => {
  return (
    <StyledButton
      size="large"
      variant="contained"
      color="primary"
      disableElevation
      {...props}
    >
      <Trans ns="common">Log Out</Trans>
    </StyledButton>
  )
}

const MAINNAVBAR_PATH_THEMES = {}

const Layout = ({
  children,
  seoProps,
  path,
  showNav = true,
  showCtaBanner = true,
  location,
}) => {
  const { isAuthenticated, loginWithRedirect, isLoading, logout } = useAuth0()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const siteMetadata = useSiteMetadata()
  const { t, language, changeLanguage, originalPath } = useI18next()
  const { open: quickSearchOpen } = useQuickSearchState()

  const MAINNAVBAR_LINKS = [
    {
      label: t('Discover'),
      component: StyledI18nLink,
      to: '/discover',
      props: {
        role: 'link',
        to: '/discover',
        onClick: () =>
          tracking.track({
            dcCode: IDX_FLOODLIGHT_TAGS.CLICK_DISCOVER_NAV_BUTTON,
          }),
      },
    },
    {
      label: t('Collections'),
      component: StyledI18nLink,
      to: '/collections',
      props: {
        role: 'link',
        to: '/collections',
        onClick: () =>
          tracking.track({
            dcCode: IDX_FLOODLIGHT_TAGS.CLICK_COLLECTIONS_NAV_BUTTON,
          }),
      },
    },
  ]

  const getAuthButtons = ({
    isAuthenticated,
    isMobile,
    isLoading,
    loginWithRedirect,
    logout,
  }) => {
    const logoutOnClick = () => {
      setStorageItem('logoutReturnTo', window.location.pathname)
      logout({
        returnTo: `${window.location.origin}/logout-success`,
      })
    }
    const signUpOnClick = () => {
      tracking.track({ dcCode: IDX_FLOODLIGHT_TAGS.CLICK_SIGNUP_NAV_BUTTON })
      loginWithRedirect({
        initialScreen: 'signUp',
        appState: {
          returnTo: window.location.pathname,
        },
      })
    }
    const signInOnClick = () => {
      tracking.track({ dcCode: IDX_FLOODLIGHT_TAGS.CLICK_LOGIN_NAV_BUTTON })
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      })
    }
    if (isMobile) {
      return isAuthenticated
        ? [
            {
              type: 'custom',
              label: t('Log Out'),
              onClick: logoutOnClick,
            },
          ]
        : [
            {
              type: 'custom',
              label: t('Sign Up'),
              onClick: signUpOnClick,
            },
            {
              type: 'custom',
              label: t('Log In'),
              onClick: signInOnClick,
            },
          ]
    }
    return isAuthenticated
      ? [
          {
            type: 'custom',
            showBreakpointOverride: 'MD',
            content: <LogoutButton onClick={logoutOnClick} />,
          },
        ]
      : [
          {
            type: 'custom',
            showBreakpointOverride: 'MD',
            content: (
              <>
                {isLoading ? (
                  <Box
                    // Note: This box is to prevent the nav from shifting
                    sx={{ minWidth: 85 }}
                  />
                ) : (
                  <AuthButton
                    label={t('Sign Up')}
                    role="link"
                    onClick={signUpOnClick}
                  />
                )}
              </>
            ),
          },
          {
            type: 'custom',
            showBreakpointOverride: 'MD',
            content: (
              <>
                {isLoading ? null : (
                  <AuthButton
                    label={t('Log In')}
                    role="link"
                    variant="outlined"
                    color="secondary"
                    onClick={signInOnClick}
                  />
                )}
              </>
            ),
          },
        ]
  }

  const isHome = originalPath === '/'
  const hamburgerBreakpoint = 'LG'
  const seoMetaProps = {
    ...siteMetadata,
    socialThumbPath,
    ...seoProps,
    lang: language,
  }
  const links = [
    ...MAINNAVBAR_LINKS,
    {
      type: 'custom',
      showBreakpointOverride: 'XS',
      hideFromHamburger: true,
      content: <QuickSearch hamburgerBreakpoint={hamburgerBreakpoint} />,
    },
    ...getAuthButtons({
      isLoading,
      isAuthenticated,
      isMobile: !matches,
      loginWithRedirect,
      logout,
    }),
  ]

  return (
    <Page id="fullpage">
      <Seo {...seoMetaProps} />
      <CookieBanner
        bodyText={t(
          'We use cookies and other tracking technologies to assist with navigation, analyze your use of our services, and assist with our promotional and marketing efforts.'
        )}
        optionsButtonText={t('View cookie options')}
        acceptButtonText={t('Accept')}
        declineButtonText={t('Decline')}
        dialogTitleText={t('Cookies at Klick.')}
        dialogBodyText={t(
          'We use cookies and other tracking technologies to assist with navigation, analyze your use of our services, and assist with our promotional and marketing efforts.'
        )}
        dialogTrackingEnabledText={t('Tracking Enabled')}
        dialogTrackingDisabledText={t('Tracking Disabled')}
        dialogCancelButtonText={t('Cancel')}
        dialogSaveButtonText={t('Save')}
      />
      <SkipToContentBar />
      <div id="back-to-top-anchor" />
      <EyebrowBar
        descriptionText={
          <Trans>
            The Klick Group of companies is an ecosystem of brilliant minds
            working to realize the full potential of their people and clients
            since&nbsp;1997.
          </Trans>
        }
        languagePicker={
          <LanguagePicker
            onChangeLanguage={(nextLanguage) => {
              changeLanguage(nextLanguage)
              setCookie(
                'klick_language',
                nextLanguage,
                365,
                window.location.host === 'localhost:8000'
                  ? undefined
                  : 'klick.com'
              )
            }}
            currentLanguage={language}
            textBlurb={t(
              'Choose your preferred language from the drop down menu and hit apply. The website page will automatically reload.'
            )}
          />
        }
        overrides={
          isHome
            ? { background: '#022896', buttonBackground: '#0343FB' }
            : undefined
        }
      />
      <StyledMainNavBar
        logoUrlOverride={getLocalizedRelativePath('/', language)}
        hamburgerBreakpoint={hamburgerBreakpoint}
        defaultTheme={isHome ? 'onPrimary' : 'onWhite'}
        $isHome={isHome}
        $quickSearchOpen={quickSearchOpen}
        links={showNav ? links : []}
        pathThemes={MAINNAVBAR_PATH_THEMES}
      />
      <Main id="main-content">{children}</Main>
      {showCtaBanner && <CtaBanner />}
      <Footer
        divider={false}
        noLocalPage={['contact']}
        itemsList={[
          {
            label: t('Discover Klick', { ns: 'common' }),
            items: [
              { label: 'Klick Health', href: 'https://www.klick.com' },
              { label: 'Klick Katalyst', href: 'https://katalyst.klick.com' },
              {
                label: 'Klick Consulting',
                href: 'https://consulting.klick.com',
              },
              { label: 'Klick Ideas Exchange', href: 'https://idx.klick.com' },
              {
                label: `${t('Careers', { ns: 'common' })} @ Klick`,
                href: 'https://careers.klick.com',
              },
              {
                label: 'Newsroom',
                href: 'https://www.klick.com/news',
              },
            ],
          },
          {
            label: '',
            items: [
              {
                label: t('Contact Us', { ns: 'common' }),
                href: 'https://www.klick.com/contact',
              },
              {
                label: t('Newsletter', { ns: 'common' }),
                href: 'https://www.klick.com/klickwire',
              },
              {
                label: t('Privacy Policy', { ns: 'common' }),
                href: 'https://www.klick.com/privacy',
              },
              {
                label: t('Global Capabilities', { ns: 'common' }),
                href: 'https://www.klick.com/global',
              },
              {
                label: t('Cookie Settings', { ns: 'common' }),
                onClick: 'showCookiesModal',
                component: 'button',
              },
              {
                label: t('Accessibility Settings', { ns: 'common' }),
                onClick: 'showAccessibilityModal',
                component: 'button',
              },
              {
                label: t('Accessibility Statement', { ns: 'common' }),
                href: 'https://www.klick.com/accessibility',
              },
              {
                label: t('Do Not Sell My Personal Information', {
                  ns: 'common',
                }),
                href: 'https://www.klick.com/privacy#ccpa',
              },
            ],
          },
        ]}
        copyrightOverride={I18nCopyright}
        lockupProps={{
          tagline: (
            <Trans ns="common">
              <span>There’s something</span> <span>different here.</span>
            </Trans>
          ),
        }}
        socialButtonsProps={{ groupLabel: t('Follow us on', { ns: 'common' }) }}
      />
      <BackToTop />
      <AccessibilityDialog />
    </Page>
  )
}

function I18nCopyright() {
  const currentYear = new Date().getFullYear()
  const { t } = useTranslation()
  return (
    <P variant="body2">
      © {currentYear} {t('All rights reserved', { ns: 'common' })}.{' '}
      <span>
        Klick&nbsp;Health<sup>®</sup> {t('is a trademark of', { ns: 'common' })}{' '}
        Klick Inc.
      </span>
    </P>
  )
}

const StyledButton = styled(Button)`
  white-space: nowrap;
`

const StyledMainNavBar = styled(MainNavBar)`
  ${match.isLG} {
    nav > ul > li:nth-child(1),
    nav > ul > li:nth-child(2),
    nav > ul > li:nth-child(3) {
      transition: opacity 250ms ease;
      opacity: ${({ $isHome, $quickSearchOpen }) =>
        $isHome && !$quickSearchOpen ? 0 : 1};
      pointer-events: ${({ $isHome, $quickSearchOpen }) =>
        $isHome && !$quickSearchOpen ? 'none' : 'all'};
      display: ${({ $isHome, $quickSearchOpen }) =>
        $isHome && !$quickSearchOpen ? 'none' : 'block'};
    }
    &.isMainNavSticky nav > ul > li:nth-child(1),
    &.isMainNavSticky nav > ul > li:nth-child(2),
    &.isMainNavSticky nav > ul > li:nth-child(3) {
      opacity: 1;
      pointer-events: all;
      display: block;
    }
  }
`

const Page = styled.div`
  position: relative;
  margin: 0;
  transition: background 300ms ease-in-out;
`

const Main = styled.main`
  margin: 0;
  padding: 0;
  transition: background 300ms ease-out, color 300ms ease-out;
`

const StyledI18nLink = styled(I18nLink)`
  display: none;

  ${match.isLG} {
    display: block;
  }
`

export default Layout
