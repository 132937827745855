import React from 'react'
import {
  Button,
  Decorator,
  ExternalLink,
  Heading,
  match,
  P,
  Section,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

const CtaBanner = () => {
  const { t } = useTranslation()
  const { ctaBg } = useStaticQuery(graphql`
    {
      ctaBg: file(relativePath: { eq: "CtaBanner/cta-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  let contentJSX = React.useMemo(() => {
    return (
      <LinkedContent
        component={ExternalLink}
        href="https://www.klick.com/klickwire"
      >
        <BackdropImage image={getImage(ctaBg)} alt="" role="presentation" />
        <StyledContainer maxWidth="lg">
          <CtaBox my={8}>
            <Box maxWidth="530px">
              <Heading component="h3" variant="h3">
                <Trans ns="common">
                  Stay Informed with the Klick&nbsp;Wire
                </Trans>
              </Heading>
              <StyledP variant="blurb2">
                <Trans ns="common">
                  See why readers open the Klick&nbsp;Wire first. Get the latest
                  digital health insights for life sciences
                  marketers&mdash;straight to your inbox&nbsp;weekly.
                </Trans>
              </StyledP>
            </Box>
          </CtaBox>
          <StyledButtton tabIndex="-1" size="xlarge">
            <Trans ns="common">Subscribe Now</Trans>
          </StyledButtton>
        </StyledContainer>
      </LinkedContent>
    )
  }, [ctaBg])

  return (
    <ThemeProvider themeType="onPrimary">
      <StyledSection name={t('Klick Wire CTA')}>{contentJSX}</StyledSection>
    </ThemeProvider>
  )
}

const StyledSection = styled(Section)`
  background: radial-gradient(50% 50% at 50% 50%, #0343fb 0%, #022897 100%);
  overflow: hidden;
  padding: 0px;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 200px;

  ${match.isMD} {
    flex-direction: row;
    text-align: left;
    padding-bottom: 0;
  }
`

const LinkedContent = styled(Box)`
  text-decoration: none;

  &:active,
  &:visited,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const CtaBox = styled(Box)`
  flex: 1;
  z-index: 1;
`

const StyledButtton = styled(Button)`
  justify-self: flex-end;
  margin-bottom: 64px;

  ${match.isMD} {
    margin-bottom: 0;
  }
`

const BackdropImage = styled(GatsbyImage)`
  position: absolute;
  height: 300px;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  aspect-ratio: 1456/518;

  ${match.isMD} {
    transform: translateX(-20%);
    height: 70%;
  }
`

const StyledP = styled(P)`
  line-height: 1.2;
`

export default CtaBanner
