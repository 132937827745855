import React from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import {
  Button,
  KlickLogo,
  match,
  ThemeProvider,
  useResizeObserver,
} from '@klickmarketing/react-components'
import { Box, Container, IconButton, Modal } from '@material-ui/core'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import styled, { css } from 'styled-components'

import { ALGOLIA_ENV_PREFIX } from '../../../config'
import { DEFAULT_LOCALE, getAlgoliaIndexNameByLocale } from '../../../languages'
import { useAlgoliaSearch } from '../../contexts/AlgoliaSearch'
import { useQuickSearchState } from '../../contexts/QuickSearchStateProvider'
import Autocomplete from '../Autocomplete/Autocomplete'

import SearchIcon from './SearchIcon'

const ICON_BUTTON_ID = 'search-button-label'
const OVERLAY_BUTTON = 'search-overlay-label'
const CLOSE_BUTTON = 'search-close-label'

const QuickSearch = ({ hamburgerBreakpoint = 'MD' }) => {
  const { open, setOpen } = useQuickSearchState()
  const [posX, setPosX] = React.useState(0)
  const [relX, setRelX] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [centerOffset, setCenterOffset] = React.useState(0)
  const inputRef = React.useRef()
  const dataRef = React.useRef({})
  const backdropRef = React.useRef()
  const buttonRef = React.useRef()
  const wrapperRef = React.useRef()
  const searchClient = useAlgoliaSearch()
  const { language } = useI18next()

  const indexName = getAlgoliaIndexNameByLocale(
    ALGOLIA_ENV_PREFIX,
    language || DEFAULT_LOCALE
  )

  const refinedSearchClient = {
    ...searchClient,
    // Note: see docs on conditional search: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
    search(requests) {
      if (searchHasLessThanTwoChars(requests)) {
        return noResults(requests)
      }

      return searchClient.search(requests)
    },
  }

  const backdropSize = useResizeObserver(backdropRef.current)

  React.useEffect(() => {
    if (open && inputRef.current) {
      document.body.style.overflowY = 'hidden'
      document.documentElement.style.overflowX = 'hidden'
      document.documentElement.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'unset'
      document.documentElement.style.overflowX = 'hidden'
      document.documentElement.style.overflowY = 'unset'
    }
  }, [open, inputRef])

  React.useEffect(() => {
    // TODO: screen wide cover effect followed by centering/expansion of search
    if (!buttonRef.current || !wrapperRef.current) return
    const buttonBounds = buttonRef.current.getBoundingClientRect()
    const wrapperBounds = wrapperRef.current.getBoundingClientRect()
    setPosX(buttonBounds.left + buttonBounds.width / 2)
    setRelX(buttonBounds.left - wrapperBounds.left)
    setCenterOffset(wrapperBounds.width / 2)

    // get the height of the backdrop to pass to the listbox
    if (backdropSize?.[0]?.contentRect) {
      const { height } = backdropSize?.[0]?.contentRect
      const header = document.getElementsByTagName('header')[0]
      if (!header) return
      const headerHeight = header.getBoundingClientRect().height
      setHeight(height + headerHeight)
    }
  }, [buttonRef, backdropSize, open])

  const handleSelect = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    window.scrollTo(0, 0)
    setOpen(true)
    setTimeout(() => {
      inputRef.current?.focus?.()
    }, 300)
  }

  const handleClose = () => {
    setOpen(false)
    dataRef.current.refine('')
    setTimeout(() => {
      buttonRef.current.style.zIndex = 1602
    }, 275)
    setTimeout(() => {
      buttonRef.current.style.zIndex = 1
    }, 1200)
  }

  const Wrapper = (children) => {
    return (
      <QuickSearchWrapper $open={open} $offset={centerOffset} $relX={relX}>
        <StyledButton
          $open={open}
          $offset={centerOffset}
          $relX={relX}
          disabled
          color="secondary"
          aria-labelledby={OVERLAY_BUTTON}
        >
          <StyledSearchIcon height="0.9375rem" width="0.9375rem" aria-hidden />
          <HiddenSpan id={OVERLAY_BUTTON}>
            <Trans>Open Search</Trans>
          </HiddenSpan>
        </StyledButton>
        <SearchInputContainer
          pr={open ? 2 : 0}
          $open={open}
          $offset={centerOffset}
          $relX={relX}
        >
          {children}
        </SearchInputContainer>
      </QuickSearchWrapper>
    )
  }

  return (
    <>
      <Modal
        open={open}
        disablePortal
        disableEscapeKeyDown
        disableScrollLock
        keepMounted
        onClose={handleClose}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1601,
        }}
      >
        <StyleProvider $open={open} ref={wrapperRef}>
          <Backdrop
            ref={backdropRef}
            style={{
              clipPath: `circle(${open ? '100vw' : '0px'} at ${posX}px 50%)`,
            }}
          >
            <ThemeProvider themeType="onPrimary">
              <BackdropContent maxWidth="lg">
                <StyledKlickLogo orientation="landscape" brand="idx" />
                <BackdropSpacer />
                <IconButton
                  onClick={handleClose}
                  aria-labelledby={CLOSE_BUTTON}
                >
                  <CloseIcon
                    $hamburgerBreakpoint={hamburgerBreakpoint}
                    $open={open}
                    aria-hidden
                  >
                    <span />
                    <span />
                    <span />
                  </CloseIcon>
                  <HiddenSpan id={CLOSE_BUTTON}>
                    <Trans>Close</Trans>
                  </HiddenSpan>
                </IconButton>
              </BackdropContent>
            </ThemeProvider>
          </Backdrop>
          <InstantSearch
            indexName={indexName}
            searchClient={refinedSearchClient}
          >
            <Autocomplete
              disabled={!open}
              onSelect={handleSelect}
              inputRef={inputRef}
              wrapper={Wrapper}
              listBoxProps={{
                offsetTop: height,
              }}
              dataRef={dataRef}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            />
          </InstantSearch>
        </StyleProvider>
      </Modal>
      <SearchButton
        color="secondary"
        onClick={handleOpen}
        ref={buttonRef}
        aria-labelledby={ICON_BUTTON_ID}
      >
        <StyledSearchIcon height="0.9375rem" width="0.9375rem" aria-hidden />
        <HiddenSpan id={ICON_BUTTON_ID}>
          <Trans>Open Search</Trans>
        </HiddenSpan>
      </SearchButton>
    </>
  )
}

function searchHasLessThanTwoChars(requests) {
  return requests.every(({ params }) => params.query.length < 2)
}

function noResults(requests) {
  return Promise.resolve({
    results: requests.map(() => ({
      hits: [],
      nbHits: 0,
      nbPages: 0,
      page: 0,
      processingTimeMS: 0,
    })),
  })
}

const StyleProvider = styled.div`
  height: 100%;
  z-index: 1602;
  --search-width: calc(90vw - 128px);

  ${match.isSM} {
    --search-width: 300px;
  }

  ${match.isMD} {
    --search-width: 450px;
  }
`

const Backdrop = styled.div`
  display: block;
  position: absolute;
  top: 0px;
  left: -16px;
  width: calc(100% + 32px);
  z-index: 1601;
  height: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  transition: 0.5s;
  overflow: hidden;

  ${match.isXS} {
    left: -24px;
    width: calc(100% + 40px);
  }

  ${match.isSM} {
    left: -32px;
    width: calc(100% + 56px);
  }

  ${match.isLG} {
    width: 100vw;
    left: calc((100vw - 1280px) / 2 * -1 - 24px);
  }
`

const BackdropContent = styled(Container)`
  display: flex;
  height: 100%;
  align-items: center;
`

const BackdropSpacer = styled.div`
  flex: 1;
`

const QuickSearchWrapper = styled(Box)`
  position: absolute;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  z-index: 1601;
  top: 50%;
  height: 35px;
  border-radius: 6rem;
  background: ${({ theme }) => theme.palette.secondary.main};
  width: ${({ $open }) => ($open ? 'var(--search-width)' : '35px')};
  transition: 0.5s;
  transform-origin: right;
  transform: ${({ $open, $relX, $offset }) =>
      $open
        ? `translateX(calc(${$offset}px - (var(--search-width) / 2)))`
        : `translateX(${$relX}px)`}
    translateY(-50%);
`

const SearchButton = styled(Button)`
  padding: 10px;
  min-width: 0px;
  border-radius: 6rem;
`

const StyledButton = styled(SearchButton)`
  background: ${({ theme }) => theme.palette.secondary.main};
  position: absolute;
  z-index: 1615;
  left: 0px;
`

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${({ theme }) => theme.palette.background.default};
`

const HiddenSpan = styled.span`
  display: none;
`

const SearchInputContainer = styled(Box)`
  position: absolute;
  border-radius: 6rem;
  padding-left: 35px;
  background: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  transition: 0.5s;
  transform-origin: right;
`

const StyledKlickLogo = styled(KlickLogo)`
  pointer-events: none;
  opacity: 0;
  height: 40px;
  z-index: 1610;

  ${match.isLG} {
    opacity: 1;
    margin: ${({ theme }) => `${theme.spacing(1)}px 0`};
    height: 58px;
  }
`

const CloseIcon = styled.div`
  width: 22px;
  height: 22px;
  margin: auto;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 22px;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 2px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  ${({ $open }) =>
    $open
      ? `
    span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 2px;
      left: 3px;
    }

    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 18px;
      left: 3px;
    }
  `
      : ''}

  ${({ $hamburgerBreakpoint }) => {
    return css`
      ${match[`is${$hamburgerBreakpoint}`]} {
        span:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: 2px;
          left: 3px;
        }

        span:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 18px;
          left: 3px;
        }
      }
    `
  }}
`

export default QuickSearch
