import React from 'react'

const SearchIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8764 12.1812C12.9556 12.2608 13 12.3685 13 12.4808C13 12.5931 12.9556 12.7008 12.8764 12.7804L12.2785 13.3783C12.2393 13.4176 12.1927 13.4488 12.1414 13.4701C12.0901 13.4913 12.0351 13.5023 11.9796 13.5023C11.924 13.5023 11.869 13.4913 11.8177 13.4701C11.7664 13.4488 11.7198 13.4176 11.6806 13.3783L8.35697 10.056C8.31372 10.0113 8.28088 9.95757 8.26079 9.8987C7.20265 10.6696 5.89266 11.0129 4.59245 10.8601C3.29224 10.7073 2.09753 10.0696 1.24694 9.07444C0.396351 8.07926 -0.0475015 6.79983 0.00403343 5.49168C0.0555684 4.18354 0.598697 2.94299 1.52494 2.01781C2.45118 1.09262 3.69235 0.550912 5.00056 0.500873C6.30875 0.450834 7.58768 0.896149 8.58189 1.74788C9.5761 2.5996 10.2124 3.79504 10.3637 5.09542C10.515 6.39581 10.1702 7.70541 9.39813 8.76266C9.45646 8.78307 9.50967 8.81589 9.55411 8.85885L12.8764 12.1812ZM5.19971 2.23626C4.51408 2.23652 3.84392 2.44008 3.27398 2.82122C2.70404 3.20235 2.25991 3.74392 1.99777 4.37747C1.73563 5.01101 1.66724 5.70805 1.80125 6.38046C1.93527 7.05287 2.26566 7.67044 2.75066 8.15507C3.23566 8.63971 3.85347 8.96964 4.52598 9.10315C5.19849 9.23665 5.89548 9.16774 6.52883 8.90512C7.16217 8.64251 7.70342 8.19798 8.08412 7.62775C8.46482 7.05753 8.66789 6.38722 8.66763 5.70158C8.66746 5.24634 8.57762 4.79559 8.40325 4.37506C8.22888 3.95454 7.97339 3.57248 7.65136 3.25069C7.32933 2.92891 6.94708 2.6737 6.52643 2.49965C6.10577 2.32559 5.65495 2.23609 5.19971 2.23626Z"
      />
    </svg>
  )
}

export default SearchIcon
