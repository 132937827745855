import React from 'react'
import { P } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
// import { BookIcon, PlayIcon } from './Icons'
import {
  MenuBook as IconMenuBook,
  Movie as IconMovie,
  Queue as IconQueue,
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'
import styled, { css } from 'styled-components'

import { formatPublishDate, generatePostUri } from '../../common/utils'
import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

const VARIANTS = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  DENSE: 'dense',
}

const generateContent = ({
  title,
  persons,
  publishDate,
  description,
  variant = VARIANTS.DEFAULT,
  isLoading = false,
  language,
}) => {
  const renderTextIfLoaded = (element) =>
    isLoading ? <Skeleton variant="text" /> : element
  switch (variant) {
    case VARIANTS.DEFAULT:
      return (
        <ContentContainer>
          <Box my={1}>
            <StyledP component="h3" variant="subtitle2">
              {renderTextIfLoaded(title)}
            </StyledP>
          </Box>
          <PersonText variant="h6">
            {renderTextIfLoaded(
              persons?.map((person) => person.name).join(', ')
            )}
          </PersonText>
          <StyledP variant="body2">
            {renderTextIfLoaded(
              publishDate ? formatPublishDate(publishDate, language) : undefined
            )}
          </StyledP>
          <StyledDecorator $variant={variant} />
          <StyledP variant="body1">
            {renderTextIfLoaded(description ? truncateText(description) : null)}
          </StyledP>
        </ContentContainer>
      )
    case VARIANTS.MEDIUM:
      return (
        <ContentContainer>
          <StyledP component="h3" variant="subtitle2">
            {renderTextIfLoaded(title)}
          </StyledP>
          <PersonText variant="h6">
            {renderTextIfLoaded(
              persons?.map((person) => person.name).join(', ')
            )}
          </PersonText>
        </ContentContainer>
      )
    case VARIANTS.DENSE:
      return (
        <ContentContainer ml={1}>
          <StyledTitle component="h6" variant="body1">
            {renderTextIfLoaded(title)}
          </StyledTitle>
          <StyledDecorator $variant={variant} />
          <PersonText variant="body2">
            {renderTextIfLoaded(
              persons?.map((person) => person.name).join(', ')
            )}
          </PersonText>
        </ContentContainer>
      )
    default:
      return undefined
  }
}

const MediaTile = ({
  time,
  type,
  title,
  persons,
  publishDate,
  description,
  variant = VARIANTS.DEFAULT,
  isLoading = false,
  slug,
  poster,
  completion = 0,
  context = 'MEDIA_TILE',
}) => {
  const { language, t } = useI18next()

  return (
    <StyledMediaTile
      component={I18nLink}
      to={generatePostUri({ type, slug })}
      $variant={variant}
    >
      <Thumbnail $variant={variant}>
        <ThumbnailContainer $variant={variant}>
          {isLoading ? (
            <div style={{ aspectRatio: '16 / 9' }}>
              <Skeleton
                variant="rect"
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          ) : (
            <>
              {!!poster && (
                <ContentfulImageWrapper
                  src={poster.url ? poster.url : poster}
                  alt=""
                  role="presentation"
                  context={context}
                />
              )}
              {!!time && <Time variant="body2">{msToTime(time)}</Time>}
            </>
          )}
          {!isLoading && (
            <TypeBox $variant={variant}>
              {type === 'ArticlePost' ? <StyledBookIcon /> : null}
              {type === 'VideoPost' ? <StyledPlayIcon /> : null}
              {type === 'Collection' ? <StyledQueueIcon /> : null}
              <TypeLabel>
                <TextContainer>
                  {type === 'ArticlePost'
                    ? t('Article', { ns: 'common' })
                    : null}
                  {type === 'VideoPost' ? t('Video', { ns: 'common' }) : null}
                  {type === 'Collection'
                    ? t('Collection', { ns: 'common' })
                    : null}{' '}
                </TextContainer>
              </TypeLabel>
            </TypeBox>
          )}
        </ThumbnailContainer>
        {/* <ProgressBar
          $variant={variant}
          aria-label="Completion for media"
          value={completion}
          max={100}
        /> */}
      </Thumbnail>
      {generateContent({
        type,
        title,
        persons,
        publishDate,
        description,
        variant,
        isLoading,
        language,
      })}
    </StyledMediaTile>
  )
}

function truncateText(text) {
  const maxLength = 180

  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
}

const msToTime = (ms) => {
  const toStringWPad = (num) => num.toString().padStart(2, '0')

  // const milliseconds = Math.floor((ms % 1000) / 100)
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24)
  const hoursTxt = hours ? `${toStringWPad(hours)}:` : ''
  const minutesTxt = toStringWPad(Math.floor((ms / (1000 * 60)) % 60))
  const secondsTxt = toStringWPad(Math.floor((ms / 1000) % 60))

  return `${hoursTxt}${minutesTxt}:${secondsTxt}`
}

const Thumbnail = styled.div`
  width: 100%;
  max-width: ${({ $variant }) =>
    $variant === VARIANTS.DENSE ? '40%' : '100%'};
`

const ThumbnailContainer = styled.div`
  width: 100%;
  padding-bottom: 62.5%;
  height: 0;
  position: relative;
`

const StyledPlayIcon = styled(IconMovie)``
const StyledBookIcon = styled(IconMenuBook)``
const StyledQueueIcon = styled(IconQueue)``

const TypeLabel = styled.div`
  overflow: hidden;
  position: relative;
  max-width: 0;
  transition: 0.2s linear max-width;

  &:after {
    padding: 0.1em 0.2em 0.1em 0.5em;
    display: block;
  }
`

const TextContainer = styled.span`
  display: block;
  padding-left: 6px;
`

const TypeBox = styled.div`
  ${({ $variant }) =>
    $variant === VARIANTS.DENSE
      ? css`
          --top-offset: 6px;
          --container-height: 24px;
          --container-padding: 8px 6px;
          --icon-size: 14px;
        `
      : css`
          --top-offset: 10px;
          --container-height: 32px;
          --container-padding: 17px 7px;
          --icon-size: 20px;
        `};

  position: absolute;
  border-radius: 25px;
  left: var(--top-offset);
  top: var(--top-offset);
  height: var(--container-height);
  padding: var(--container-padding);
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  transition: 3s linear all;

  & > ${StyledBookIcon}, & > ${StyledPlayIcon}, & > ${StyledQueueIcon} {
    height: var(--icon-size);
    width: var(--icon-size);
  }
`

const StyledMediaTile = styled(Box)`
  text-decoration: none;
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: ${({ $variant }) =>
    $variant === VARIANTS.DENSE ? 'row' : 'column'};
  align-items: unset;

  &:hover,
  &:focus {
    & ${TypeLabel} {
      max-width: ${({ $variant }) =>
        $variant === VARIANTS.DENSE ? '0px' : '100px'};
    }
  }
`

const ContentContainer = styled(Box)`
  margin-top: 0px;
  flex: 1;
`

const StyledP = styled(P)`
  margin: 0px;
`

const StyledTitle = styled(P)`
  margin: 0px;
  line-height: 1;
  font-weight: 900;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

const PersonText = styled(StyledP)`
  margin-top: 0px;
  /* Note: was asked to make names break on to next line, but it would not surprise me if this changes back, so leaving commented out. */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
`

const StyledDecorator = styled.div`
  margin: 8px 0px 14px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-width: ${({ $variant }) =>
    $variant === VARIANTS.DENSE ? '1px' : '2px'};
  width: ${({ $variant }) => ($variant === VARIANTS.DENSE ? '16px' : '4vw')};
  min-width: ${({ $variant }) =>
    $variant === VARIANTS.DENSE ? '16px' : '50px'};
`

const Time = styled(StyledP)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 4px;
`

const ProgressBar = styled.progress`
  height: ${({ $variant }) => ($variant === VARIANTS.DENSE ? '3px' : '6px')};
  width: 100%;
  background: #4d4d4d;
  display: block;
  vertical-align: 0px;
  border: none;

  &::-webkit-progress-bar {
    background: #4d4d4d;
  }
}`

export default MediaTile
