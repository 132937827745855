import React from 'react'
import { match, ThemeProvider } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

const Listbox = React.forwardRef(function (props, ref) {
  const { children, offsetTop, ...rest } = props
  const itemData = React.Children.toArray(children)

  return (
    <ThemeProvider themeType="onBlack">
      <SearchContainer $offsetTop={offsetTop}>
        <SearchContent
          ref={ref}
          {...rest}
          role="listbox"
          component="ul"
          $offsetTop={offsetTop}
        >
          {itemData.map((child, idx) =>
            React.cloneElement(child, { key: `QS_item@idx=${idx}` })
          )}
        </SearchContent>
      </SearchContainer>
    </ThemeProvider>
  )
})

const SearchContainer = styled(Box)`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 1610;
  overflow: hidden;

  ${match.isMD} {
    position: fixed;
    left: calc(50vw - (450px / 2));
    width: 450px;
    height: fit-content;
    background: transparent;
    max-height: 75vh;
    overflow: auto;
    top: ${({ $offsetTop }) => `${$offsetTop}px`};
  }
`

const SearchContent = styled(Box)`
  position: relative;
  background: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  padding: ${({ $offsetTop }) => `${$offsetTop + 8}px 0px`};

  ${match.isMD} {
    padding: 8px 0px;
    max-height: fit-content;
  }
`

export default Listbox
